import React, { useCallback, useState } from "react";

import { CubeProvider } from "@cubejs-client/react";
import cubejs from "@cubejs-client/core";

import { useSelector } from "react-redux";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import { Line } from "react-chartjs-2";

import DataSetsCardTitle from "../DataSetsCardTitle";

import dataset from "../../../../images/dataSetEmpty.png";
import ChartRenderer from "../../../UI/dataHubCalculatedCharts/component/ChartRenderer";
import useKpiData from "./useKpiData";
import { Button } from "@material-ui/core";
import CustomButton from "../../../UI/button/button";

const API_URL = " https://cubeprod.imgrows.app";

const CUBEJS_TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NzExMDY1NDMsImV4cCI6MTY3MTE5Mjk0M30.-9_171MOu0Vs8rfl2f_siqdys9SYph6xfZDvFL81ilI";

// const cubejsApi = cubejs(CUBEJS_TOKEN, {
//   apiUrl: `${API_URL}/cubejs-api/v1`,
// });

function DataSetRight() {
  const {
    kpiCubeData,
    handleChartTimeFilter,
    displayedCharts,
    setDisplayedCharts,
  } = useKpiData();

  const chartsToLoad = 10; // Number of charts to load per click

  const dataHubStore = useSelector((state) => state.dataHub);

  const { selectedTopic } = dataHubStore;

  const [open, setOpen] = useState(null);

  const [selectedKpi, setSelectedKpi] = useState(null);

  const cubejsApi = React.useMemo(
    () =>
      cubejs({
        apiUrl: `${API_URL}/cubejs-api/v1`,
        headers: {
          Authorization: ` ${selectedTopic}` || "",
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedTopic]
  );

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleOpenMenu = useCallback((event, kpi) => {
    setOpen(event.currentTarget);
    setSelectedKpi(kpi);
  }, []);

  const handleTimeSelect = () => {};

  return (
    <CubeProvider cubejsApi={cubejsApi}>
      <Paper
        elevation={3}
        style={{ backgroundColor: "#FEFEFE", height: "100%" }}
      >
        <DataSetsCardTitle title={"Key Performing Activities"} />
        {kpiCubeData.length === 0 && (
          <div style={{ height: "90%", marginTop: "auto" }}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="column"
              style={{ height: "90%", marginTop: "auto" }}
            >
              <Grid
                item
                md={12}
                xs={12}
                sm={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "15px",
                  flexDirection: "column",
                }}
              >
                <img
                  src={dataset}
                  alt=""
                  style={{
                    marginTop: "6%",
                    width: "280px",
                    height: "254px",
                  }}
                />
                <Typography
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "18px",
                    lineHeight: "32px",
                    textAlign: "center",
                    textTransform: "capitalize",
                    color: "#242424",
                    padding: "1px 15px",
                  }}
                >
                  {selectedTopic
                    ? "No KPI’s availble"
                    : "Please select a topic to view its KPI’s"}
                </Typography>
              </Grid>
            </Grid>
          </div>
        )}
        {kpiCubeData.length > 0 && (
          <Box style={{ height: "90%", overflow: "scroll" }}>
            {kpiCubeData.slice(0, displayedCharts).map((item) => {
              return (
                <>
                  <Box
                    style={{
                      border: "1px solid #EBF1F8",
                      borderRadius: "8px",
                      margin: "10px 20px 10px 20px",
                      height: "50px",
                    }}
                  >
                    <DataSetsCardTitle
                      fontSize={"14px"}
                      hasLine={true}
                      title={item?.name}
                      handleOpenMenu={handleOpenMenu}
                    />
                  </Box>
                  <Box
                    style={{ height: "50%", width: "100%", padding: "10px" }}
                  >
                    <ChartRenderer
                      vizState={item.vizState}
                      pivotConfig={item?.pivotConfig}
                      chartX_AxisCustomize={item?.chartX_AxisCustomize}
                      chartY_AxisCustomize={item?.chartY_AxisCustomize}
                    />
                  </Box>
                </>
              );
            })}
            {displayedCharts < kpiCubeData.length && (
              <div
                style={{
                  textAlign: "center",
                  marginLeft: "2em",
                  marginBottom: "10px",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    border: "1px solid #ccc",
                    margin: "10px 0",
                    position: "absolute",
                    width: "98%",
                    top: "25%",
                  }}
                />
                <CustomButton
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    setDisplayedCharts((prev) => prev + chartsToLoad);
                  }}
                  style={{
                    position: "relative",
                    backgroundColor: "white",
                    zIndex: 1,
                  }}
                >
                  Load More
                </CustomButton>
              </div>
            )}
          </Box>
        )}
      </Paper>
      <Menu
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
        variant="selectedMenu"
      >
        <MenuItem
          onClick={() => {
            handleChartTimeFilter(selectedKpi, "day");
            handleCloseMenu();
            handleTimeSelect();
          }}
        >
          day
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleChartTimeFilter(selectedKpi, "week");
            handleCloseMenu();
            handleTimeSelect();
          }}
        >
          Week
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleChartTimeFilter(selectedKpi, "month");
            handleTimeSelect();
            handleCloseMenu();
          }}
        >
          Month
        </MenuItem>
      </Menu>
    </CubeProvider>
  );
}

export default DataSetRight;
